<ng-container *ngIf="breadcrumbs">
  <div class="flex flex-wrap">
    <ng-container *ngFor="let breadcrumb of breadcrumbs">
      <a [routerLink]="breadcrumb.url" [queryParams]="breadcrumb.queryParams" class="flex items-center mb-4 breadcrumb">
        <span> {{ breadcrumb.label }}</span>
        <mat-icon>chevron_right</mat-icon>
      </a>
    </ng-container>
  </div>
</ng-container>
