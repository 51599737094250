import {Component, HostBinding, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, NgForm, Validators} from "@angular/forms";
import {catchError, forkJoin, of, Subject, takeUntil, timer} from "rxjs";
import {MatSnackBar} from "@angular/material/snack-bar";
import {CustomerSelectionService} from "../../shared/services/customer-selection.service";
import {Contract} from "../../shared/interfaces/contract";
import {environment} from "../../../../environments/environment";
import {ActivatedRoute, Router} from "@angular/router";
import {ContactService} from "../services/contact.service";
import {ContactCategory} from '../interfaces/contact-category';
import {ContactReason} from "../interfaces/contact-reason";
import {ContactRequest} from "../../faq/interfaces/contact-request";
import {ContactRequestSource} from "../../faq/enumerations/contact-request-source";
import {CustomerType} from "../../faq/enumerations/customer-type";
import {Salutation} from "../../faq/enumerations/salutation";
import {FaqService} from "../../faq/services/faq.service";
import {withSlowLoadingStatus} from "../../observable-status/extensions/observable.extension";

@Component({
  selector: 'app-contact-view',
  templateUrl: './contact.component.html'
})
export class ContactComponent implements OnInit, OnDestroy {

  private refresh$ = new Subject<void>();
  private destroyed$ = new Subject<void>();
  contactFormGroup: FormGroup | null = null;
  @ViewChild('contactForm') contactForm: NgForm | null = null;
  showSubCategories: boolean = false;
  showTextarea: boolean = false;
  possibleCategories: ContactCategory[] | null = null;
  isSaveProcessOngoing: boolean = false;
  selectedContract: Contract | null = null;
  maximumMessageLength: number = 500;
  environment = environment;
  selectedCategory: ContactCategory | null = null;
  selectedReason: ContactReason | null = null;
  @HostBinding('class') flexClass = 'flex-1';

  selectedFile: File | null = null;

  constructor(private contactService: ContactService,
              private snackBar: MatSnackBar,
              private customerSelectionService: CustomerSelectionService,
              private router: Router,
              private route: ActivatedRoute,
              private formBuilder: FormBuilder,
              private faqService: FaqService) {
  }

  ngOnInit(): void {
    this.contactFormGroup = this.formBuilder.group({
      mainCategory: [null, []],
      subCategory: [null, []],
      contactMessage: [null, [Validators.required, Validators.maxLength(this.maximumMessageLength)]],
      uploadedFile: [null]
    });
    this.refresh$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        if (!this.selectedContract) {
          return;
        }
        forkJoin(
          {
            loadContactCategories: this.contactService
              .getContactCategories()
              .pipe(catchError(() => of(false))),
            awaitMinimumLoadingTime: timer(environment.minimumLoadingTimeInMilliseconds)
          }
        ).subscribe(observer => {
          if (observer.loadContactCategories !== false) {
            this.possibleCategories = observer.loadContactCategories as ContactCategory[];
            this.contactService.replacePlaceholders(this.possibleCategories, environment);
          }
        });
      });
    this.customerSelectionService.contractSelected$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(contract => {
        this.selectedContract = contract;
        this.refresh();
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  onMainCategoryChange(): void {
    this.contactFormGroup!.controls.contactMessage.setErrors(null);
    this.showTextarea = false;
    this.showSubCategories = false;
  }

  onSubCategoryChange(): void {
    this.contactFormGroup!.controls.contactMessage.setErrors(null);
    this.showTextarea = true;
  }

  onShowSubCategories(): void {
    this.showSubCategories = true;
  }

  refresh(): void {
    this.refresh$.next();
  }

  save(): void {
    if (this.contactForm!.invalid || this.isSaveProcessOngoing) {
      return;
    }
    let contactRequest: ContactRequest = {
      email: this.selectedContract!.customerAddress.person.emailAddress!,
      source: ContactRequestSource.PortalContact,
      customerType: CustomerType.Customer,
      salutation: Salutation.NotSpecified,
      reason: this.selectedCategory?.selectionText ?? '',
      subReason: this.selectedReason?.selectionText ?? '',
      contractId: this.selectedContract!.contractId ?? '',
      message: this.contactFormGroup!.controls.contactMessage.value,
      filename: undefined,
      file: null
    };

    if (!this.selectedFile) {
      this.submitContactRequest(contactRequest);
      return;
    }

    this.convertFileToBase64(this.selectedFile)
      .then(result => {
        contactRequest.filename = this.selectedFile?.name;
        contactRequest.file = result.split(',')[1];
        this.submitContactRequest(contactRequest);
      });
  }

  private submitContactRequest(contactRequest: ContactRequest): void {
    withSlowLoadingStatus(
      this.faqService.createContactRequest(contactRequest)
    )
      .pipe(takeUntil(this.destroyed$))
      .subscribe(contactSendResponseState => {
        this.isSaveProcessOngoing = contactSendResponseState.isInLoadingState;
        if (contactSendResponseState.isInErrorState) {
          let errorMessage = 'Fehler beim Speichern der Nachricht oder Hochladen der Datei. Bitte versuchen Sie es später erneut.';
          this.snackBar.open(errorMessage, 'OK', {
            panelClass: 'snack-danger'
          });
        } else if (contactSendResponseState.isInValueState) {
          if (contactSendResponseState.value!.isSuccessFul) {
            this.router.navigate([environment.contactMessageSent], {relativeTo: this.route});
          } else {
            let errorMessage = 'Es liegt ein Fehler in Ihrer Datei vor. Datei konnte nicht hochgeladen werden.';
            this.snackBar.open(errorMessage, 'OK', {
              panelClass: 'snack-danger'
            });
          }
        }
      });
  }

  private convertFileToBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  }
}
