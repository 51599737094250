import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AuthService} from '../../shared/services/auth.service';
import {Faq} from "../interfaces/faq";
import {CustomerType} from "../enumerations/customer-type";
import {ContactRequest} from "../interfaces/contact-request";
import {ContactRequestResponse} from "../interfaces/contact-request-response";

@Injectable({
  providedIn: 'root'
})

export class FaqService {

  constructor(private authService: AuthService, private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) {
  }

  getFaq(
    tagId: number | null,
    topicId: number | null): Observable<Faq> {
    let headers = new HttpHeaders()
      .set('authorization', `Bearer ${this.authService.jwt}`);

    if (tagId != null)
      return this.http.get<Faq>(`${this.baseUrl}api/faq?tagId=` + tagId.toString(), {headers: headers});

    if (topicId != null)
      return this.http.get<Faq>(`${this.baseUrl}api/faq?topicId=` + topicId.toString(), {headers: headers});

    return this.http.get<Faq>(`${this.baseUrl}api/faq`, {headers: headers});
  }

  getFaqTopicForQuestionAsync(questionId: number): Observable<number | null> {
    let headers = new HttpHeaders()
      .set('authorization', `Bearer ${this.authService.jwt}`);

    return this.http.get<number>(`${this.baseUrl}api/faq/questions?questionId=${questionId}`, {headers: headers});
  }

  getFaqContact(customerType: CustomerType, topicId: number | null): Observable<Faq> {
    let headers = new HttpHeaders()
      .set('authorization', `Bearer ${this.authService.jwt}`);
    if (topicId != null)
      return this.http.get<Faq>(`${this.baseUrl}api/faq/contact?customerType=${customerType}&topicId=${topicId}`, {headers: headers});

    return this.http.get<Faq>(`${this.baseUrl}api/faq/contact?customerType=${customerType}`, {headers: headers});
  }

  createContactRequest(contactRequest: ContactRequest): Observable<ContactRequestResponse> {
    let headers = new HttpHeaders()
      .set('authorization', `Bearer ${this.authService.jwt}`);
    return this.http.post<ContactRequestResponse>(`${this.baseUrl}api/faq/contact/`, contactRequest, {headers: headers});
  }
}
