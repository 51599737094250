<header class="border-b border-gray-200">
  <div class="container mx-auto">
    <div class="w-full flex flex-wrap items-center sm:justify-center py-5">
      <ng-container *ngIf="environment.isFaqVisible">
        <div class="hidden sm:flex w-1/3"></div>
      </ng-container>
      <a href="{{environment.homePageUrl}}" class="homeLink w-3/5 sm:w-1/3" target="_blank"></a>
      <ng-container *ngIf="environment.isFaqVisible">
        <div class="w-1/3 flex justify-end pr-4">
          <a class="flex items-center"
             [routerLink]='["/faq"]'>
            <mat-icon class="material-symbols-outlined mr-1">help</mat-icon>
            <span class="text-font hidden lg:block">Hilfe & Kontakt</span>
          </a>
          <ng-container *ngIf="!authService.contract">
            <a class="flex items-center ml-4 lg:ml-8"
               [routerLink]='["/authentication/login"]'>
              <mat-icon class="mr-1">login</mat-icon>
              <span class="text-font hidden lg:block">LogIn</span>
            </a>
          </ng-container>
        </div>
      </ng-container>
    </div>
  </div>
</header>
<div *ngIf="authService.contract" class="topNavWrapper hidden sm:flex w-100 bg-[#f5f5f5]">
  <div class="container mx-auto flex flex-row flex-wrap justify-between items-center py-2 px-4">
    <span><span class="font-medium">VERTRAG</span> {{ authService.contract.contractId }}</span>
    <div class="topNavLinkWrapper flex">
      <a routerLink="/{{ environment.dashboardUrl }}" class="flex items-center">
        <mat-icon class="text-[#3c3c3b]">grid_view</mat-icon>
        <span class="text-[#4a4a4a]">Übersicht</span>
      </a>
      <a *ngIf="showTariff" routerLink="/{{ environment.tariffUrl }}" class="flex items-center ml-8">
        <mat-icon class="text-[#3c3c3b] material-symbols-outlined">broken_image</mat-icon>
        <span class="text-[#4a4a4a]">Tarif</span>
      </a>
      <a (click)="logout()" class="flex items-center ml-8">
        <mat-icon class="text-[#3c3c3b]">logout</mat-icon>
        <span class="text-[#4a4a4a]">Logout</span>
      </a>
    </div>
  </div>
</div>
