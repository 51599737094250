<ng-container *ngIf="subTopics.length > 0">
  <div appDragScroll
       class="py-5 overflow-x-auto whitespace-nowrap hide-scrollbar cursor-grab active:cursor-grabbing">
    <div class="inline-flex gap-3">
          <span (click)="onFilter(null)"
                class="py-2 px-3 rounded-lg border m-1 cursor-pointer"
                [ngClass]="{'border-primary-color primaryColor': activeSubTopicId === null}">
           Top Themen
          </span>
      <ng-container *ngFor="let subTopic of subTopics">
            <span (click)="onFilter(subTopic.id)"
                  class="py-2 px-3 rounded-lg border m-1 cursor-pointer"
                  [ngClass]="{'border-primary-color primaryColor': activeSubTopicId === subTopic.id}">
              {{ subTopic.topic }}
            </span>
      </ng-container>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="questions && questions.length > 0">
  <div class="flex flex-wrap" [ngClass]="{'mt-12': subTopics.length === 0}">
    <mat-accordion class="w-full contact">
      <mat-expansion-panel *ngFor="let question of visibleQuestions"
                           [expanded]="visibleQuestions.length === 1 || questionId === question.id"
                           [attr.id]="'question-' + question.id">
        <mat-expansion-panel-header>
          <mat-panel-title class="mat-title !mb-0">
            {{ question.question }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div [innerHTML]="question.answer"></div>
      </mat-expansion-panel>
    </mat-accordion>

    <ng-container *ngIf="!allQuestionsLoaded">
      <div class="w-full flex justify-center my-8">
        <button type="button" customer-portal-button
                mat-raised-button
                class="w-auto !px-10 load-more-button !rounded-md"
                (click)="loadMore()">
          Mehr Inhalte laden
        </button>
      </div>
    </ng-container>
  </div>
</ng-container>

<ng-container *ngIf="questions.length === 0">
  <div class="w-full my-8">
    <cpc-notification [notificationType]="NotificationType.Information"
                      notification="Keine Fragen verfügbar.">
    </cpc-notification>
  </div>
</ng-container>
