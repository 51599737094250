import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, Router} from '@angular/router';

@Injectable({providedIn: 'root'})
export class CustomerTypeGuard implements CanActivate {
  constructor(private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const customerType = route.queryParams['customerType'];

    if (customerType) {
      return true;
    }
    this.router.navigate(['/faq/contact']);
    return false;
  }
}
