import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from "../../shared/services/auth.service";
import { FileUploadResult } from '../interfaces/file-upload-result';
import { FileUploadType } from '../enumerations/file-upload-type';


@Injectable({
  providedIn: 'root'
})
export class FileUploadService {

  constructor(
    private authService: AuthService,
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string
  ) {}

  uploadFile(contractId: string, file: File, fileType: FileUploadType = FileUploadType.Miscellaneous): Observable<FileUploadResult> {
    const formData = new FormData();
    formData.append(file.name, file);
    
    const headers = new HttpHeaders()
      .set('authorization', `Bearer ${this.authService.jwt}`);

    return this.http.post<FileUploadResult>(`${this.baseUrl}api/file-upload/${contractId}/${fileType}`, formData, { headers });
  }
}
