<div class="container mx-auto px-4 py-8">
  <app-breadcrumb></app-breadcrumb>
  <ng-container *ngIf="tag">
    <mat-card-title>
      <h1 class="mat-headline">
        Suchergebnisse: {{ tag.tag }}
      </h1>
    </mat-card-title>

    <app-questions [questions]="questions"></app-questions>

  </ng-container>

  <ng-container *ngIf="faqState.isInLoadingState">
    <div class="m-auto flex items-center my-16 w-full z-10">
      <mat-spinner class="m-auto"></mat-spinner>
    </div>
  </ng-container>

  <ng-container *ngIf="faqState.isInErrorState">
    <cpc-notification [notificationType]="NotificationType.Error"
                      notification="Die Themen sind derzeit nicht verfügbar. Bitte versuchen Sie es später erneut.">
    </cpc-notification>
  </ng-container>
</div>


