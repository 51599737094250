<div [class]="class">
  <div [ngClass]="{'information': notificationType === NotificationType.Information, 'success': notificationType === NotificationType.Success, 'warning': notificationType === NotificationType.Warning, 'error': notificationType === NotificationType.Error}"
       class="w-full flex !items-start text-icon rounded d-inline-flex p-2">
    <span *ngIf="notificationType === NotificationType.Error" class="mt-2 material-symbols-outlined">
      error
    </span>
    <span *ngIf="notificationType === NotificationType.Information" class="mt-2 material-symbols-outlined">
      info
    </span>
    <span *ngIf="notificationType === NotificationType.Warning" class="mt-2 material-symbols-outlined">
      warning
    </span>
    <span *ngIf="notificationType === NotificationType.Success" class="mt-2 material-symbols-outlined">
      check_circle
    </span>
    <p class="p-1 !m-1"><b>{{notification}}</b></p>
  </div>
</div>
