import {Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import {Subject, takeUntil} from "rxjs";
import {ObservableStatus} from "../../observable-status/classes/observable-status";
import {Faq} from "../interfaces/faq";
import {Topic} from "../interfaces/topic";
import {FaqService} from "../services/faq.service";
import {switchMap} from "rxjs/operators";
import {withNormalLoadingStatus} from "../../observable-status/extensions/observable.extension";
import {NotificationType} from '../../notification/enumerations/notification-type';
import {environment} from "../../../../environments/environment";
import {Favourite} from "../interfaces/favourite";
import {CustomerType} from "../enumerations/customer-type";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-contact-topic',
  templateUrl: './contact-topic.component.html'
})
export class ContactTopicComponent implements OnInit, OnDestroy {
  private refresh$ = new Subject<void>();
  private destroyed$ = new Subject<void>();

  @HostBinding('class') flexClass = 'flex-1';

  NotificationType = NotificationType;
  environment = environment;

  contactState: ObservableStatus<Faq> = ObservableStatus.none<Faq>();
  customerType: CustomerType = CustomerType.NoCustomer;
  favourites: Favourite[] = [];
  contactTopics: Topic[] = [];

  constructor(private faqService: FaqService,
              private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.customerType = this.route.snapshot.queryParams['customerType'];

    this.refresh$
      .pipe(
        switchMap(() => {
          return withNormalLoadingStatus(this.faqService.getFaqContact(this.customerType, null))
        }),
        takeUntil(this.destroyed$)
      ).subscribe(contactState => {
      this.favourites = contactState.value?.favourites || [];
      this.contactTopics = contactState.value?.topics || [];
      this.contactState = contactState;
    });

    if (this.customerType)
      this.refresh();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  refresh(): void {
    this.refresh$.next();
  }
}
