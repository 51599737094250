<app-breadcrumb></app-breadcrumb>

<mat-card-title>
  <h1 class="mat-headline">
    Anfrage
  </h1>
</mat-card-title>

<ng-container *ngIf="contactState.isInLoadingState">
  <div class="m-auto flex items-center my-16 w-full z-10">
    <mat-spinner class="m-auto"></mat-spinner>
  </div>
</ng-container>

<ng-container *ngIf="favourites.length">
  <div class="flex flex-wrap">
    <p class="font-bold">Wird Ihre Frage hier schon beantwortet?</p>
    <div class="w-full">
      <app-favourites [favourites]="favourites"></app-favourites>
    </div>
  </div>

  <div class="w-full opacity-30 uppercase my-8 text-center">ODER</div>
</ng-container>

<div class="flex flex-wrap">
  <p class="w-full font-bold">Haben Sie ein anderes Anliegen?</p>
  <div class="w-full sm:w-1/2 lg:w-1/3 md:pr-4">
    <div (click)="onShowSubReasons()"
         class="p-4 rounded-2xl flex items-center justify-between border cursor-pointer"
         [ngClass]="{'border-primary-color': isSubReasonsVisible}">
        <span class="flex"><mat-icon color="primary" class="mr-2 material-symbols-outlined" matPrefix>input </mat-icon>
          <h1 class="mat-subheading-2 !my-0"> Anderes Anliegen</h1>
        </span>
      <ng-container *ngIf="isSubReasonsVisible">
        <mat-icon color="primary" class="material-symbols-outlined" matPrefix>sync</mat-icon>
      </ng-container>
    </div>
  </div>

  <ng-container *ngIf="subReasons.length > 0 && isSubReasonsVisible">
    <div class="w-full my-4">
      <p class="font-bold">Worum geht es genau?</p>
      <div class="w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 justify-center">
        <ng-container *ngFor="let subReason of subReasons">
          <ng-container *ngIf="!selectedSubReason || selectedSubReason === subReason">
            <div (click)="onShowContactForm(subReason)"
                 class="p-4 rounded-2xl flex items-center justify-between border cursor-pointer"
                 [ngClass]="{'border-primary-color': isTopicSyncVisible || subReasons.length === 1}">
                <span class="flex">
                  <mat-icon color="primary" class="mr-2 material-symbols-outlined" matPrefix> {{ subReason.icon }} </mat-icon>
                  <h1 class="mat-subheading-2 !my-0"> {{ subReason.topic }}</h1>
                </span>
              <ng-container *ngIf="subReasons.length > 1">
                <ng-container *ngIf="isTopicSyncVisible">
                  <mat-icon color="primary" class="material-symbols-outlined" matPrefix>sync</mat-icon>
                </ng-container>
              </ng-container>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="isContactFormVisible">
    <div class="w-full my-4">
      <p class="font-bold">Ihre Daten</p>

      <div class="w-full md:w-2/3">
        <form *ngIf="contactFormGroup" #contactForm="ngForm" [formGroup]="contactFormGroup" (ngSubmit)="save()">
          <div class="w-full grid grid-cols-8 gap-4">
            <ng-container *ngIf="customerType === CustomerType.NoCustomer">
              <div class="col-span-8 lg:col-span-2">
                <mat-form-field appearance="fill" class="w-full">
                  <mat-label>Anrede</mat-label>
                  <mat-select formControlName="salutation">
                    <mat-option *ngFor="let salutation of salutations"
                                [value]="salutation.key">{{ salutation.label }}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="contactFormGroup.controls.salutation.errors?.required">
                    Dieses Feld ist erforderlich.
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="col-span-8 md:col-span-4 lg:col-span-3">
                <mat-form-field appearance="fill" class="w-full">
                  <mat-label>Vorname</mat-label>
                  <input formControlName="firstName" type="text" matInput>
                  <mat-error *ngIf="contactFormGroup.controls.firstName.errors?.required">
                    Dieses Feld ist erforderlich.
                  </mat-error>
                  <mat-error *ngIf="contactFormGroup.controls.firstName.errors?.maxlength">
                    Maximal {{ maxLengthFirstName }} Zeichen
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="col-span-8 md:col-span-4 lg:col-span-3">
                <mat-form-field appearance="fill" class="w-full">
                  <mat-label>Nachname</mat-label>
                  <input formControlName="lastName"
                         type="text"
                         matInput>
                  <mat-error *ngIf="contactFormGroup.controls.lastName.errors?.required">
                    Dieses Feld ist erforderlich.
                  </mat-error>
                  <mat-error *ngIf="contactFormGroup.controls.lastName.errors?.maxlength">
                    Maximal {{ maxLengthLastName }} Zeichen
                  </mat-error>
                </mat-form-field>
              </div>
            </ng-container>

            <div class="col-span-8 md:col-span-4">
              <mat-form-field appearance="fill" class="w-full">
                <mat-label>E-Mail-Adresse</mat-label>
                <input formControlName="email" type="email" matInput>
                <mat-error *ngIf="contactFormGroup.controls.email.errors?.required">
                  Dieses Feld ist erforderlich.
                </mat-error>
                <mat-error *ngIf="contactFormGroup.controls.email.errors?.maxlength">
                  Maximal {{ maxLengthEmailAddress }} Zeichen
                </mat-error>
                <mat-error *ngIf="contactFormGroup.controls.email.errors?.email">
                  Die eingegebene E-Mail-Adresse ist nicht korrekt
                </mat-error>
              </mat-form-field>
            </div>
            <ng-container *ngIf="customerType === CustomerType.NoCustomer">
              <div class="col-span-8 md:col-span-4">
                <mat-form-field appearance="fill" class="w-full">
                  <mat-label>Telefon</mat-label>
                  <input formControlName="phone"
                         type="text"
                         matInput>
                  <mat-error *ngIf="contactFormGroup.controls.phone.errors?.required">
                    Dieses Feld ist erforderlich.
                  </mat-error>
                  <mat-error *ngIf="contactFormGroup.controls.phone.errors?.maxlength">
                    Maximal {{ maxLengthPhoneNumber }} Zeichen
                  </mat-error>
                </mat-form-field>
              </div>
            </ng-container>
            <ng-container *ngIf="customerType === CustomerType.Customer">
              <div class="col-span-8 md:col-span-4">
                <mat-form-field appearance="fill" class="w-full">
                  <mat-label>Vertragsnummer</mat-label>
                  <input formControlName="contractId" type="text" matInput>
                  <mat-error *ngIf="contactFormGroup.controls.contractId.errors?.maxlength">
                    Maximal {{ maximumLengthCustomerId }} Zeichen
                  </mat-error>
                </mat-form-field>
              </div>
            </ng-container>

            <div class="col-span-8">
              <mat-form-field appearance="fill" class="w-full">
                <mat-label>Ihre Nachricht</mat-label>
                <textarea
                  matInput
                  formControlName="message"
                  cdkTextareaAutosize
                  cdkAutosizeMinRows="4">
                  </textarea>
                <mat-hint class="block ml-3">[{{ contactFormGroup.controls.message.value?.length ?? 0 }}
                  / {{ maximumMessageLength }}]
                </mat-hint>
                <mat-error *ngIf="contactFormGroup.controls.message.errors?.required">
                  Dieses Feld ist erforderlich.
                </mat-error>
                <mat-error *ngIf="contactFormGroup.controls.message.errors?.maxlength">
                  [{{ contactFormGroup.controls.message.value?.length ?? 0 }} / {{ maximumMessageLength }}]
                </mat-error>
              </mat-form-field>
            </div>

            <div class="col-span-8">
              <div class="w-full flex flex-wrap justify-center p-5 border border-gray-500 border-dotted relative">
                 <span class="w-full text-center text-sm opacity-60">
                  Anhang (max. {{ maximumAllowedMb }} MB) <br> JPG, JPEG, PNG oder PDF
                 </span>
                <ng-container *ngIf="filePreview">
                  <button mat-button (click)="deleteFile()" class="!absolute right-0 top-3">
                    <mat-icon class="material-symbols-outlined opacity-30 mx-3">delete</mat-icon>
                  </button>
                </ng-container>
                <button type="button" customer-portal-button
                        mat-raised-button
                        class="w-auto px-10 load-more-button !rounded-md !my-4"
                        (click)="fileInput.click()">Datei öffnen
                </button>
                <input type="file" #fileInput
                       (change)="onFileSelected($event)" hidden
                       [accept]="acceptedFileExtensions.join(',')"/>

                <ng-container *ngIf="fileName">
                  <span class="w-full text-center">{{ fileName }}</span>
                </ng-container>
                <mat-error class="w-full text-center font-small" *ngIf="contactFormGroup.controls.file?.errors?.invalidFile">
                  Es werden nur Dateien bis zu einer Größe von {{ maximumAllowedMb }} MB unterstützt
                </mat-error>
                <mat-error class="w-full text-center font-small" *ngIf="contactFormGroup.controls.file?.errors?.invalidFileType">
                  Es werden nur PDF, JPG, JPEG und PNG Dateien unterstützt.
                </mat-error>
              </div>
            </div>

            <div class="col-span-8">
              <mat-checkbox formControlName="privacyConsent" class="contact mt-4" color="primary">
                <p><b>Datenschutz *</b></p>
              </mat-checkbox>
              <div>
                <p>Wir nutzen Ihre E-Mail-Adresse dazu, Ihre über das Kontaktformular gesendete Anfrage
                  zu beantworten. Die Übertragung erfolgt SSL verschlüsselt.
                  Ihre mitgeteilten Daten werden zu keinem anderen Zweck verwendet und nicht an Dritte weitergeleitet.
                  Bitte lesen Sie dazu auch unseren Datenschutz.</p>
                <p>Bitte bestätigen Sie Ihr Einverständnis:<br>
                  Mit dem Versenden des Formulars bestätige ich, das ich die
                  <a href="{{environment.dataProtectionWebUrl}}" target="_blank">Datenschutzerklärung</a>
                  der {{ environment.copyrightBy }} gelesen habe und mit dem darin beschriebenen Umgang mit meinen personenbezogenen
                  Daten einverstanden bin. Dieses Einverständnis kann jederzeit widerrufen werden.
                  Im Fall eines Widerrufs kann es allerdings sein, dass Sie gewisse Dienste nicht mehr in vollem Umfang nutzen können.
                  Hierzu reicht eine elektronische Mitteilung an {{ environment.brandEmail }} aus. *</p></div>
              <mat-error class="font-small"
                         *ngIf="contactForm.submitted && contactFormGroup.controls.privacyConsent.errors?.required">
                Dieses Feld ist erforderlich.
              </mat-error>
            </div>
          </div>

          <div class="col-span-8 my-8">
            <div class="flex flex-wrap justify-between items-center">
              <p class="my-10 mat-h2 !mb-0">Sicherheitsüberprüfung</p>
              <div class="flex flex-wrap justify-between items-center">
                <button type="button" (click)="refreshCaptcha()" class="mx-3">
                  <mat-icon class="material-symbols-outlined opacity-30">sync</mat-icon>
                </button>
                <button type="button" matTooltip="Zum Schutz vor Bots verwenden wir Captchas. Sie stellen sicher, dass nur echte Nutzer Aktionen durchführen können – so verhindern wir den Versand von Fake-E-Mails."
                        matTooltipClass="tooltip-primary"
                        matTooltipPosition="above">
                  <mat-icon class="material-symbols-outlined opacity-30">info</mat-icon>
                </button>
              </div>
            </div>
            <div class="flex flex-wrap justify-center items-center my-4">
              <span class="primaryColor captcha text-center w-full">{{ captcha }}</span>
            </div>
            <mat-form-field appearance="fill" class="w-full">
              <mat-label>Zeichenfolge</mat-label>
              <input formControlName="captchaInput" type="text" matInput>
              <mat-error *ngIf="contactFormGroup.controls.captchaInput.errors?.required">
                Dieses Feld ist erforderlich.
              </mat-error>
              <mat-error *ngIf="contactFormGroup.controls.captchaInput.value !== '' &&
                contactFormGroup.controls.captchaInput.invalid &&
                contactFormGroup.controls.captchaInput.touched">
                CAPTCHA ist falsch.
              </mat-error>
            </mat-form-field>
          </div>

          <div class="flex justify-center my-8">
            <div class="w-auto">
              <app-button caption="Absenden" color="primary" type="submit"
                          [progressBarVisible]="contactSendResponseState.isInLoadingState"></app-button>
            </div>
          </div>
        </form>

        <ng-container *ngIf="contactSendResponseState.value?.isSuccessFul || isResettingForm">
          <cpc-notification [class]="'mt-12'" [notificationType]="NotificationType.Success"
                            notification="Vielen Dank für Ihre Nachricht. Sie wurde gesendet.">
          </cpc-notification>
        </ng-container>

        <ng-container *ngIf="contactSendResponseState.isInErrorState">
          <cpc-notification [class]="'mt-12'" [notificationType]="NotificationType.Error"
                            notification="Fehler beim Speichern der Nachricht. Bitte versuchen Sie es später erneut.">
          </cpc-notification>
        </ng-container>

        <ng-container *ngIf="contactSendResponseState.value?.virusScanStatus === VirusScanStatus.VirusDetected">
          <cpc-notification [class]="'mt-12'" [notificationType]="NotificationType.Error"
                            notification="Es liegt ein Fehler in Ihrer Datei vor. Datei konnte nicht hochgeladen werden. Bitte versuchen Sie es später erneut.">
          </cpc-notification>
        </ng-container>
      </div>
    </div>
  </ng-container>
</div>
