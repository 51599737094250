import {Injectable} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Breadcrumb} from "../interfaces/breadcrumb";

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbService {
  constructor(private activatedRoute: ActivatedRoute) {
  }

  getBreadcrumbs(): Breadcrumb[] {
    const breadcrumbs: Breadcrumb[] = [];

    const customerType = this.activatedRoute.snapshot.queryParamMap.get('customerType');
    const topicId = this.activatedRoute.snapshot.queryParamMap.get('topicId');

    if (!customerType && !topicId) {
      breadcrumbs.push({ label: 'Hilfe & Kontakt', url: '/faq' });
    } else if (customerType && !topicId) {
      breadcrumbs.push({ label: 'Hilfe & Kontakt', url: '/faq' });
      breadcrumbs.push({ label: 'Kontakt', url: '/faq/contact' });
    } else if (customerType && topicId) {
      breadcrumbs.push({ label: 'Hilfe & Kontakt', url: '/faq' });
      breadcrumbs.push({ label: 'Kontakt', url: '/faq/contact' });
      breadcrumbs.push({
        label: 'Thema',
        url: '/faq/contact',
        queryParams: { customerType: customerType }
      });
    }

    return breadcrumbs;
  }
}
