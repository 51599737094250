import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { MeterReadingIssueType } from '../enumerations/meter-reading-issue-type';

@Pipe({ name: 'meterReadingIssueType' })
@Injectable({ providedIn: 'root' })
export class MeterReadingIssueTypePipe implements PipeTransform {

  private meterReadingIssueTypeMap = {
    [MeterReadingIssueType.HigherThanExpected]: 'Der eingegebene {{type}}Zählerstand ist höher als erwartet. Bitte achten Sie darauf nur die Vorkommastellen einzugeben und prüfen Sie Ihre Eingabe vor dem Absenden.',
    [MeterReadingIssueType.LowerThanExpected]: 'Der eingegebene {{type}}Zählerstand ist niedriger als erwartet. Bitte achten Sie darauf nur die Vorkommastellen einzugeben und prüfen Sie Ihre Eingabe vor dem Absenden.',
    [MeterReadingIssueType.MoreDigitsThanExpected]: 'Der eingegebene {{type}}Zählerstand ist länger als die maximale Anzahl an Stellen, die der Zähler unterstützt. Bitte achten Sie darauf nur die Vorkommastellen einzugeben und prüfen Sie Ihre Eingabe vor dem Absenden.',
    [MeterReadingIssueType.OutsideDelivery]: 'Das Datum des eingegebenen {{type}}Zählerstands liegt außerhalb des Belieferungszeitraums. Bitte prüfen Sie Ihre Eingabe vor dem Absenden.',
  };

  transform(meterReadingIssueType: MeterReadingIssueType, meterReadingType: string = ''): string {
    let template = this.meterReadingIssueTypeMap[meterReadingIssueType];
    return template.replace('{{type}}', meterReadingType ? `${meterReadingType}-` : '');
  }

}
