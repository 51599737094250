import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {Question} from "../interfaces/question";
import {NotificationType} from '../../notification/enumerations/notification-type';
import {Topic} from "../interfaces/topic";

@Component({
  selector: 'app-questions',
  templateUrl: './questions.component.html'
})
export class QuestionsComponent implements OnInit, AfterViewInit {
  @Input() allQuestions: Question[] = [];
  @Input() questions: Question[] = [];
  @Input() subTopics: Topic[] = [];
  @Input() questionId: number | null = null;

  visibleQuestions: Question[] = [];
  visibleQuestionsCount: number = 0;
  questionsPerLoad: number = 5;
  allQuestionsLoaded: boolean = false;
  activeSubTopicId: number | null = null;

  NotificationType = NotificationType;

  constructor() {
  }

  ngOnInit(): void {
    if (this.questions)
      this.resetPagination();
  }

  ngAfterViewInit(): void {
    if (this.questionId && this.visibleQuestions) {
      setTimeout(() => {
        this.scrollToQuestion(this.questionId!);
      }, 0);
    }
  }

  loadMore() {
    if (this.allQuestionsLoaded) {
      return;
    }
    const nextItems = this.questions.slice(this.visibleQuestionsCount, this.visibleQuestionsCount + this.questionsPerLoad);

    this.visibleQuestions = [...this.visibleQuestions, ...nextItems];
    this.visibleQuestionsCount += this.questionsPerLoad;
    this.allQuestionsLoaded = this.visibleQuestionsCount >= this.questions.length;
  }

  private resetPagination(): void {
    if (this.questionId) {
      this.visibleQuestions = this.questions;
      this.allQuestionsLoaded = true;
      return;
    }

    this.visibleQuestions = [];
    this.visibleQuestionsCount = 0;
    this.allQuestionsLoaded = false;
    this.loadMore();
  }

  onFilter(subtopicId: number | null) {
    this.activeSubTopicId = subtopicId;
    if (this.allQuestions) {
      subtopicId === null ?
        this.questions = this.allQuestions :
        this.questions = this.allQuestions.filter(
          question => question.topicId === subtopicId);
    }
    this.resetPagination();
  }

  scrollToQuestion(questionId: number): void {
    const element = document.getElementById(`question-${questionId}`);
    if (element)
      element.scrollIntoView({behavior: 'smooth', block: 'start'});
  }
}
