<div [class]="class">
  <span class="w-full font-small opacity-60 my-2 flex">
    Anhang (max. {{ maximumAllowedMb }} MB) <br> JPG, JPEG, PNG oder PDF
  </span>
  <mat-form-field class="w-full" appearance="fill">
    <mat-label>Datei hochladen</mat-label>
    <input type="file" #fileUpload (change)="onFileSelected($event)" [accept]="acceptedFileExtensions" hidden/>
    <input matInput readonly [value]="file?.name || 'Keine Datei ausgewählt...'" class="fileUpload"/>
    <div class="flex items-center" matSuffix>
      <ng-container *ngIf="file">
        <button mat-icon-button type="button" (click)="deleteFile()">
          <mat-icon class="opacity-30">delete</mat-icon>
        </button>
      </ng-container>
      <button mat-icon-button type="button" (click)="fileUpload.click()">
        <mat-icon>attach_file</mat-icon>
      </button>
    </div>
  </mat-form-field>
</div>
