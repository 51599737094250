import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContactComponent } from './components/contact.component';
import { ContactSuccessComponent } from "./components/contact-success-component";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCardModule } from '@angular/material/card';
import { ContactRoutingModule } from "./contact-routing.module";
import { SharedModule } from "../shared/shared.module";
import { MatSelectModule } from "@angular/material/select";
import { ContactCardComponent } from './components/contact-card.component';
import { FileUploadModule } from '../file-upload/file-upload.module';

@NgModule({
  declarations: [
    ContactComponent,
    ContactSuccessComponent,
    ContactCardComponent
  ],
  exports: [
    ContactCardComponent
  ], 
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatIconModule,
    MatProgressBarModule,
    MatButtonModule,
    MatCardModule,
    MatProgressSpinnerModule,
    ContactRoutingModule,
    SharedModule,
    MatSelectModule,
    FileUploadModule,
  ]
})
export class ContactModule { }
