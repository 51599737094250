export enum PriceComponentType {
    KWK = 'KWK',
    Offshore = 'Offshore',
    NEV = 'NEV',
    Stromsteuer = 'Stromsteuer',
    Netznutzungsentgelt = 'Netznutzungsentgelt',
    Konzessionsabgabe = 'Konzessionsabgabe',
    CO2 = 'CO2',
    Ergassteuer = 'Ergassteuer',
    Konvertierungsentgelt = 'Konvertierungsentgelt',
    Bilanzierungsumlage = 'Bilanzierungsumlage',
    Speicherumlage = 'Speicherumlage'
  }
