import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {FaqComponent} from "./components/faq.component";
import {environment} from "../../../environments/environment";
import {ContactComponent} from "./components/contact.component";
import {TopicComponent} from "./components/topic.component";
import {ContactTopicComponent} from "./components/contact-topic.component";
import {ContactTopicRequestComponent} from "./components/contact-topic-request.component";
import {TagComponent} from "./components/tag.component";
import {CustomerTypeGuard} from "./guard/customer-type.guard";
import {TopicIdGuard} from "./guard/topicId.guard";

const routes: Routes = [
  {
    title: 'Hilfe & Kontakt | ' + environment.brandName,
    path: 'faq',
    children: [
      {
        path: '',
        component: FaqComponent,
      },
      {
        path: 'contact',
        component: ContactComponent,
      },
      {
        path: 'contact',
        component: ContactTopicComponent,
        canActivate: [CustomerTypeGuard],
      },
      {
        path: 'contact',
        component: ContactTopicRequestComponent,
        canActivate: [TopicIdGuard]
      },
      {
        path: 'tags/:id',
        component: TagComponent,
      },
      {
        path: 'topics/:id',
        component: TopicComponent,
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FaqRoutingModule {
}
