import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivate, Router} from "@angular/router";

@Injectable({ providedIn: 'root' })
export class TopicIdGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const topicId = route.queryParams['topicId'];
    const customerType = route.queryParams['customerType'];

    if (topicId && customerType) {
      return true;
    }
    this.router.navigate(['/faq/contact']);
    return false;
  }
}
