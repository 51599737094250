<div class="container mx-auto px-4 py-8">
  <mat-card-title>
    <h1 class="mat-headline !my-0 !mb-6">
      Zählerstand melden
    </h1>
  </mat-card-title>
  <div class="flex flex-col md:flex-row">
    <div class="w-full md:flex-1 md:mr-5 mb-10 md:mb-0">
      <app-meter-reading-create (createdMeterReading)="refresh()"></app-meter-reading-create>
      <h1 class="mat-headline!mb-6 !mt-10">Zählerstandsübersicht</h1>
      <app-meter-reading-view #meterReadingView></app-meter-reading-view>
    </div>
    <div class="w-full md:w-4/12">
      <app-meter-faq></app-meter-faq>
    </div>
  </div>
</div>
