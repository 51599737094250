<div class="scroll-container w-full relative">
  <div class="w-full px-1 hide-scrollbar"
       [ngClass]="{
    'single': favourites.length === 1,
    'double': favourites.length === 2,
    'triple': favourites.length === 3,
    'scrollable': favourites.length > 3}"
       #scrollContainer>
    <div class="my-8 gap-4 flex">
      <mat-card class="!p-6 !flex flex-wrap flex-col cursor-pointer  scroll-item"
                [ngClass]="{
              'category-favourite': favourite.type === FavouriteType.NewTab}"
                *ngFor="let favourite of favourites; let i = index"
                [id]="'slide' + i"
                (click)="openFavourite(favourite)">
        <mat-card-title>
          <mat-icon color="primary" class="mr-2 material-symbols-outlined icon-size-contact" matPrefix>
            {{ favourite.icon }}
          </mat-icon>
        </mat-card-title>
        <mat-card-content class="!flex-grow">
          <div class="mat-title pb-8" [innerHTML]="favourite.favourite"></div>
        </mat-card-content>
        <mat-card-actions class="mt-auto text-center !mb-0">
        <span class="uppercase opacity-40">
          {{ getRouteLabelDisplay(favourite.type) }}
        </span>
        </mat-card-actions>
      </mat-card>
    </div>
  </div>

  <ng-container *ngIf="showButtons && showPrevButton">
    <button type="button" color="primary" matTooltip="Prev" class="custom-fab-button !absolute left-0" (click)="scrollLeft()">
      <mat-icon>west</mat-icon>
    </button>
  </ng-container>
  <ng-container *ngIf="showButtons && showNextButton">
    <button type="button" color="primary" matTooltip="Next" class="custom-fab-button !absolute right-0" (click)="scrollRight()">
      <mat-icon>east</mat-icon>
    </button>
  </ng-container>
</div>

<ng-container *ngIf="showButtons">
  <ul class="slick-dots">
    <li
      *ngFor="let page of visiblePages; let i = index"
      [class.active]="currentIndex === i"
      (click)="goToPage(i)">
      <button type="button">{{ page + 1 }}</button>
    </li>
  </ul>
</ng-container>
