<app-breadcrumb></app-breadcrumb>

<mat-card-title>
  <h1 class="mat-headline">
    Kontakt
  </h1>
</mat-card-title>

<div class="flex flex-wrap my-8">
  <p class="w-full font-bold">Sind Sie bereits Kunde von {{ environment.brandName }}?</p>

  <div class="w-full md:w-72 py-4 md:pr-4" (click)="onCustomerType(CustomerType.Customer)">
    <div class="p-4 rounded-2xl flex items-center justify-between border cursor-pointer">
        <span class="flex items-center">
          <mat-icon color="primary" class="mr-2 material-symbols-outlined  icon-size-contact" matPrefix>person </mat-icon>
          <h1 class="mat-subheading-2 !my-0">Ich bin Kunde</h1>
        </span>
    </div>
  </div>

  <div class="w-full md:w-72 py-4 md:pr-4" (click)="onCustomerType(CustomerType.NoCustomer)">
    <div class="p-4 rounded-2xl flex items-center justify-between border cursor-pointer">
        <span class="flex items-center">
          <mat-icon color="primary" class="mr-2 material-symbols-outlined icon-size-contact" matPrefix>person_off </mat-icon>
          <h1 class="mat-subheading-2 !my-0">Ich bin kein Kunde</h1>
        </span>
    </div>
  </div>
</div>
