<ng-container *ngIf="meter.isInValueState && meter.value && meterReadingFormGroup">
  <span class="mat-title !my-0">
      Neuer Zählerstand
  </span>
  <form (ngSubmit)="save()" #meterReadingForm="ngForm" [formGroup]="meterReadingFormGroup">
    <fieldset [disabled]="meterReadingSaveOperation.isInLoadingState">
      <div class="flex flex-col md:flex-row md:flex-wrap">
        <div class="flex flex-col md:flex-row md:gap-x-5 mb-5 w-full">
          <div class="flex-1 mb-5 md:mb-0">
            <mat-form-field class="w-full" appearance="fill">
              <mat-label>Zählernummer</mat-label>
              <input type="text" disabled [value]="meter.value.meterNumber" matInput style="color: #bdbdbd!important">
            </mat-form-field>
          </div>
          <div class="flex-1">
            <mat-form-field class="w-full" appearance="fill" floatLabel='always'>
              <mat-label>Ablesungsdatum</mat-label>
              <input placeholder="TT.MM.JJJJ" matInput [matDatepicker]="picker" formControlName="readingDate">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
              <mat-error *ngIf="meterReadingFormGroup.controls.readingDate.errors?.required">
                Pflichtfeld
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="flex flex-col md:flex-row md:gap-x-5 w-full">
          <div class="flex-1 mb-5 md:mb-0">
            <mat-form-field class="w-full" appearance="fill">
              <mat-label><span>Zählerstand</span><span *ngIf="meter.value.hasNtGauge"> (HT)</span></mat-label>
              <input type="number" matInput formControlName="readingValueHt">
              <span *ngIf="selectedContract!.deliveryInformation.energyType === EnergyType.Electricity" matSuffix>kWh</span>
              <span *ngIf="selectedContract!.deliveryInformation.energyType === EnergyType.Gas" matSuffix>m³</span>
              <mat-error *ngIf="meterReadingFormGroup.controls.readingValueHt.errors?.required">
                Pflichtfeld
              </mat-error>
              <mat-error *ngIf="meterReadingFormGroup.controls.readingValueHt.errors?.min">
                Minimum {{minimumReadingValue}}
              </mat-error>
              <mat-error *ngIf="meterReadingFormGroup.controls.readingValueHt.errors?.max">
                Maximum {{maximumReadingValue}}
              </mat-error>
            </mat-form-field>
          </div>
          <div [hidden]="!meter.value.hasNtGauge" class="flex-1 mb-5 md:mb-0">
            <mat-form-field class="w-full" appearance="fill">
              <mat-label>Zählerstand (NT)</mat-label>
              <input type="number" matInput formControlName="readingValueNt">
              <span *ngIf="selectedContract!.deliveryInformation.energyType === EnergyType.Electricity" matSuffix>kWh</span>
              <span *ngIf="selectedContract!.deliveryInformation.energyType === EnergyType.Gas" matSuffix>m³</span>
              <mat-error *ngIf="meterReadingFormGroup.controls.readingValueNt.errors?.min">
                Minimum {{minimumReadingValue}}
              </mat-error>
              <mat-error *ngIf="meterReadingFormGroup.controls.readingValueNt.errors?.max">
                Maximum {{maximumReadingValue}}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="flex flex-row mt-5 w-full">
          <mat-error *ngIf="meterReadingFormGroup.errors?.oneOfBoth && meterReadingForm.submitted">
            Bitte geben Sie mindestens einen Zählerstand (HT/NT) ein
          </mat-error>
        </div>
        <ng-container *ngIf="meterReadingValidity.value">
          <ng-container *ngIf="meterReadingValidity.value.htValidity.errors.length > 0">
            <ng-container *ngFor="let error of meterReadingValidity.value.htValidity.errors">
              <cpc-notification class="mb-1 w-full" [notificationType]="NotificationType.Error"
                                [notification]="error|meterReadingIssueType: (meter.value.hasNtGauge ? 'HT' : '')">
              </cpc-notification>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="meterReadingValidity.value.htValidity.warnings.length > 0">
            <ng-container *ngFor="let warning of meterReadingValidity.value.htValidity.warnings">
              <cpc-notification class="mb-1 w-full" [notificationType]="NotificationType.Warning"
                                [notification]="warning|meterReadingIssueType: (meter.value.hasNtGauge ? 'HT' : '')">
              </cpc-notification>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="meter.value.hasNtGauge">
            <ng-container *ngIf="meterReadingValidity.value.ntValidity.errors.length > 0">
              <ng-container *ngFor="let error of meterReadingValidity.value.ntValidity.errors">
                <cpc-notification class="mb-1 w-full" [notificationType]="NotificationType.Error"
                                  [notification]="error|meterReadingIssueType: 'NT'">
                </cpc-notification>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="meterReadingValidity.value.ntValidity.warnings.length > 0">
              <ng-container *ngFor="let warning of meterReadingValidity.value.ntValidity.warnings">
                <cpc-notification class="mb-1 w-full" [notificationType]="NotificationType.Warning"
                                  [notification]="warning|meterReadingIssueType: 'NT'">
                </cpc-notification>
              </ng-container>
            </ng-container>
          </ng-container>
        </ng-container>
        <div class="w-full flex mt-3">
          <div class="w-full md:w-auto">
            <app-button caption="Absenden" color="primary" type="submit" [progressBarVisible]="meterReadingSaveOperation.isInLoadingState"></app-button>
          </div>
        </div>
      </div>
    </fieldset>
  </form>
</ng-container>
<ng-container *ngIf="meter.isInErrorState">
  <cpc-notification [notificationType]="NotificationType.Error"  notification="Ihr Zählerständ kann zurzeit nicht angepasst werden – Bitte versuchen Sie es später erneut."></cpc-notification>
</ng-container>
<ng-container *ngIf="meter.isInNoValueState">
  <cpc-notification [notificationType]="NotificationType.Warning" notification="Es wurde kein aktiver Zähler gefunden."></cpc-notification>
</ng-container>
<ng-container *ngIf="meter.isInLoadingState">
  <div class="flex flex-col items-center">
    <mat-spinner></mat-spinner>
  </div>
</ng-container>

