import {Component, OnInit} from '@angular/core';
import {BreadcrumbService} from "../services/breadcrumb.service";
import {Breadcrumb} from "../interfaces/breadcrumb";

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html'
})
export class BreadcrumbComponent implements OnInit {
  breadcrumbs: Breadcrumb[] = [];

  constructor(protected breadcrumbService: BreadcrumbService) {
  }

  ngOnInit(): void {
    this.breadcrumbs = this.breadcrumbService.getBreadcrumbs();
  }
}
