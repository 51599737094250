import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {MagicKeyCreationRequestResponse} from '../interfaces/magic-key-creation-request-response';
import {MagicKeyLogin} from '../interfaces/magic-key-login';

@Injectable({
  providedIn: 'root'
})
export class MagicKeyService {

  constructor(private httpClient: HttpClient, @Inject('BASE_URL') private baseUrl: string) {
  }

  getValidityStatus(magicKey: string): Observable<boolean> {
    return this.httpClient.get<boolean>(`${this.baseUrl}api/magic-keys/${magicKey}/validity-status`);
  }

  create(contractId: string, magicKeyReasonId: number | null): Observable<MagicKeyCreationRequestResponse> {
    return this.httpClient.post<MagicKeyCreationRequestResponse>(`${this.baseUrl}api/magic-keys/create`, {contractId: contractId, magicKeyReasonId: magicKeyReasonId});
  }

  login(magicKeyLogin: MagicKeyLogin): Observable<string> {
    return this.httpClient.post(`${this.baseUrl}api/login/magic-key`, magicKeyLogin, {responseType: 'text'});
  }

}
