import {Component, HostBinding} from '@angular/core';
import {CustomerType} from "../enumerations/customer-type";
import {Router} from "@angular/router";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-contact-customer-type',
  templateUrl: './contact-customer-type.component.html'
})
export class ContactCustomerTypeComponent {

  @HostBinding('class') flexClass = 'flex-1';
  CustomerType = CustomerType;
  environment = environment;
  customerType: CustomerType | null = null;

  constructor(private router: Router) {
  }

  onCustomerType(customerType: CustomerType) {
    this.router.navigate(['/faq/contact'], {queryParams: {customerType: customerType}});
  }
}
