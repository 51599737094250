import {Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import {map, Observable, startWith, Subject, takeUntil} from "rxjs";
import {FormControl} from "@angular/forms";
import {Tag} from "../interfaces/tag";
import {ObservableStatus} from "../../observable-status/classes/observable-status";
import {Faq} from "../interfaces/faq";
import {Topic} from "../interfaces/topic";
import {Favourite} from "../interfaces/favourite";
import {FaqService} from "../services/faq.service";
import {switchMap} from "rxjs/operators";
import {withNormalLoadingStatus} from "../../observable-status/extensions/observable.extension";
import {environment} from "../../../../environments/environment";
import {NotificationType} from '../../notification/enumerations/notification-type';
import {Question} from "../interfaces/question";
import {Router} from "@angular/router";
import {MetaService} from "../services/meta.service";

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html'
})
export class FaqComponent implements OnInit, OnDestroy {
  private refresh$ = new Subject<void>();
  private destroyed$ = new Subject<void>();

  @HostBinding('class') flexClass = 'flex-1';

  NotificationType = NotificationType;
  environment = environment;

  faqState: ObservableStatus<Faq> = ObservableStatus.none<Faq>();
  tags: Tag[] = [];
  searchTags: Tag[] = [];
  favourites: Favourite[] = [];
  topics: Topic[] = [];
  subTopics: Topic[] = [];
  questions: Question[] = [];
  currentQuestionId: number | null = null;

  tagControl = new FormControl('');
  filteredTags$: Observable<Tag[]> | null = null;

  constructor(private faqService: FaqService,
              private router: Router,
              private metaService: MetaService) {
  }

  ngOnInit(): void {
    this.refresh$
      .pipe(
        switchMap(() => {
          return withNormalLoadingStatus(
            this.faqService.getFaq(null, null)
          );
        }),
        takeUntil(this.destroyed$)
      ).subscribe(faqState => {
      this.faqState = faqState;
      this.tags = faqState?.value?.tags || [];
      this.searchTags = faqState?.value?.searchTags || [];
      this.favourites = faqState.value?.favourites || [];
      this.topics = faqState.value?.topics || [];
      this.subTopics = faqState.value?.subTopics || [];
      this.questions = faqState.value?.questions || [];
      this.currentQuestionId = faqState.value?.currentQuestionId || null;
      const keywords = faqState.value?.keywords?.map(keyword => keyword.tag) || [];
      this.metaService.updateKeywords(keywords);
    });

    this.refresh();

    this.filteredTags$ = this.tagControl.valueChanges.pipe(
      startWith(''),
      map(value => this.filterTags(value || ''))
    );
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  refresh(): void {
    this.refresh$.next();
  }

  onTagClick(tag: Tag) {
    this.router.navigate(['/faq/tags', tag.id.toString()]);
  }

  private filterTags(value: string | null | undefined): Tag[] {
    const filterValue = typeof value === 'string' ? value.toLowerCase() : '';
    return this.tags.filter(tag => tag.tag.toLowerCase().includes(filterValue));
  }
}
