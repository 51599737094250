import {AfterViewInit, ChangeDetectorRef, Component, HostBinding, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {combineLatest, distinctUntilChanged, of, Subject, switchMap, takeUntil} from "rxjs";
import {ObservableStatus} from "../../observable-status/classes/observable-status";
import {Favourite} from "../interfaces/favourite";
import {FaqService} from "../services/faq.service";
import {withNormalLoadingStatus, withSlowLoadingStatus} from "../../observable-status/extensions/observable.extension";
import {SalutationSelectionService} from "../services/salutation-selection.service";
import {AbstractControl, FormBuilder, FormGroup, NgForm, ValidationErrors, Validators} from "@angular/forms";
import {Salutation} from "../enumerations/salutation";
import {KeyLabelViewModel} from "../interfaces/key-label-view-model";
import {CustomerType} from "../enumerations/customer-type";
import {environment} from "../../../../environments/environment";
import {Faq} from "../interfaces/faq";
import {Topic} from "../interfaces/topic";
import {NotificationType} from '../../notification/enumerations/notification-type';
import {ContactRequest} from "../interfaces/contact-request";
import {ContactRequestSource} from "../enumerations/contact-request-source";
import {ContactRequestResponse} from "../interfaces/contact-request-response";
import {VirusScanStatus} from "../enumerations/virus-scan-status";

@Component({
  selector: 'app-contact-topic-request',
  templateUrl: './contact-topic-request.component.html'
})
export class ContactTopicRequestComponent implements OnInit, OnDestroy, AfterViewInit {
  @HostBinding('class') flexClass = 'flex-1';
  private refresh$ = new Subject<void>();
  private destroyed$ = new Subject<void>();

  topicId: number | null = null;
  contactState: ObservableStatus<Faq> = ObservableStatus.none<Faq>();
  reason!: string;
  customerType!: CustomerType;
  favourites: Favourite[] = [];
  subReasons: Topic[] = [];
  subReason!: string;
  selectedSubReason: Topic | null = null;

  isSubReasonsVisible: boolean = false;
  isContactFormVisible: boolean = false;
  isTopicSyncVisible: boolean = false;
  isResettingForm: boolean = false;

  contactFormGroup!: FormGroup;
  @ViewChild('contactForm') contactForm?: NgForm;
  captcha: string = '';
  salutations: KeyLabelViewModel<Salutation>[] = [];

  contactSendResponseState: ObservableStatus<ContactRequestResponse> = ObservableStatus.none<ContactRequestResponse>();
  fileName: string = '';
  filePreview: boolean = false;
  selectedFile: string | null = null;

  maximumMessageLength: number = 500;
  maximumLengthCustomerId: number = 20;
  maxLengthFirstName: number = 100;
  maxLengthLastName: number = 100;
  maxLengthEmailAddress: number = 100;
  maxLengthPhoneNumber: number = 100;
  readonly acceptedFileExtensions = environment.acceptedFileUploadExtensions;
  readonly maximumFileSizeInBytes: number = environment.maximumFileLengthInBytes;
  readonly maximumAllowedMb = parseFloat((this.maximumFileSizeInBytes / 1024 / 1024).toFixed(2));

  CustomerType = CustomerType;
  NotificationType = NotificationType;
  VirusScanStatus = VirusScanStatus;

  environment = environment;

  constructor(private activatedRoute: ActivatedRoute,
              private faqService: FaqService,
              private salutationSelectionService: SalutationSelectionService,
              private formBuilder: FormBuilder,
              private cdr: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.salutations = this.salutationSelectionService.getSalutationViewModels();
    this.topicId = this.activatedRoute.snapshot.params.id || null;
    this.customerType = this.activatedRoute.snapshot.queryParamMap.get('customerType') as CustomerType;
    this.topicId = Number(this.activatedRoute.snapshot.queryParamMap.get('topicId'));

    this.initForm();
    this.setupSubscriptions();
  }

  ngAfterViewInit(): void {
    this.refreshCaptcha();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private initForm(): void {
    this.contactFormGroup = this.formBuilder.group({
      salutation: [Salutation.NotSpecified],
      firstName: [null],
      lastName: [null],
      email: [null, [Validators.required, Validators.email, Validators.maxLength(this.maxLengthEmailAddress)]],
      phone: [null],
      contractId: ['', [Validators.maxLength(this.maximumLengthCustomerId)]],
      message: [null, [Validators.required, Validators.maxLength(this.maximumMessageLength)]],
      filename: [null],
      file: [null],
      privacyConsent: [false, [Validators.requiredTrue]],
      captchaInput: [null, [Validators.required, this.captchaValidator.bind(this)]]
    });

    this.contactFormGroup.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => this.resetNotifications()
      );
  }

  private resetNotifications(): void {
    this.contactSendResponseState = ObservableStatus.none<ContactRequestResponse>();
  }

  private setupSubscriptions(): void {
    this.refresh$
      .pipe(
        distinctUntilChanged(),
        switchMap(() => {
          if (!this.customerType && !this.topicId) {
            return of([
              ObservableStatus.none<Faq>(),
              ObservableStatus.none<Faq>()
            ] as [ObservableStatus<Faq>, ObservableStatus<Faq>]);
          }
          return combineLatest([
            withNormalLoadingStatus(this.faqService.getFaqContact(this.customerType, this.topicId)),
            withNormalLoadingStatus(this.faqService.getFaqContact(this.customerType, null))
          ]);
        }),
        takeUntil(this.destroyed$)
      ).subscribe(([contactState, topicState]) => {
      this.contactState = contactState;
      if (this.contactState.isInValueState) {
        this.favourites = contactState.value?.favourites || [];
        this.subReasons = contactState.value?.subTopics || [];
      }

      if (topicState.isInValueState) {
        this.reason = topicState.value?.topics.find(t => t.id === this.topicId)?.topic ?? '';
      }
    });

    if (this.customerType) {
      this.refresh();
      this.updateValidationForCustomerType();
    }
  }

  refresh(): void {
    this.refresh$.next();
  }

  updateValidationForCustomerType() {
    if (this.customerType === CustomerType.Customer) {
      this.contactFormGroup.controls.salutation?.setValidators([]);
      this.contactFormGroup.controls.firstName?.setValidators([
        Validators.maxLength(this.maxLengthFirstName)]);
      this.contactFormGroup.controls.lastName?.setValidators([
        Validators.maxLength(this.maxLengthLastName)]);
      this.contactFormGroup.controls.phone?.setValidators([
        Validators.maxLength(this.maxLengthPhoneNumber)]);
    } else {
      this.contactFormGroup.controls.salutation?.setValidators([
        Validators.required]);
      this.contactFormGroup.controls.firstName?.setValidators([
        Validators.required,
        Validators.maxLength(this.maxLengthFirstName)]);
      this.contactFormGroup.controls.lastName?.setValidators([
        Validators.required,
        Validators.maxLength(this.maxLengthLastName)]);
      this.contactFormGroup.controls.phone?.setValidators([
        Validators.required,
        Validators.maxLength(this.maxLengthPhoneNumber)]);
    }
    this.contactFormGroup.controls.salutation?.updateValueAndValidity();
    this.contactFormGroup.controls.firstName?.updateValueAndValidity();
    this.contactFormGroup.controls.lastName?.updateValueAndValidity();
    this.contactFormGroup.controls.phone?.updateValueAndValidity();
  }

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    this.deleteFile();
    if (input.files?.length) {
      this.resetNotifications();
      const file = input.files[0];
      const fileExtension = file.name.slice(file.name.lastIndexOf('.')).toLowerCase();

      if (file.size > this.maximumFileSizeInBytes) {
        this.setFileError('invalidFile');
      } else if (!this.acceptedFileExtensions.includes(fileExtension)) {
        this.setFileError('invalidFileType');
      } else {
        this.fileName = file.name;
        this.filePreview = true;

        this.convertFileToBase64(file).then((base64: string) => {
          this.selectedFile = base64.split(',')[1];
        });
      }
    }
  }

  convertFileToBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  }

  private setFileError(error: string): void {
    this.fileName = '';
    this.contactFormGroup.controls.file.setErrors({[error]: true});
  }

  deleteFile(): void {
    this.fileName = '';
    this.contactFormGroup.controls.file.reset();
    this.filePreview = false;
  }

  refreshCaptcha(): void {
    this.captcha = this.generateCaptcha(6);
    this.cdr.detectChanges();
  }

  private generateCaptcha(length: number): string {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    return Array.from({length}, () => characters.charAt(Math.floor(Math.random() * characters.length))).join('');
  }

  private captchaValidator(control: AbstractControl): ValidationErrors | null {
    return control.value === this.captcha ? null : {invalidCaptcha: true};
  }

  onShowSubReasons() {
    this.isSubReasonsVisible = !this.isSubReasonsVisible;
    if (this.subReasons.length === 1) {
      this.isContactFormVisible = true;

      this.selectedSubReason = this.subReasons[0];
      this.subReason = this.selectedSubReason.topic;
    }
    if (!this.isSubReasonsVisible) {
      this.isContactFormVisible = false;
      this.isTopicSyncVisible = false;
      this.selectedSubReason = null;
    }
  }

  onShowContactForm(selectedTopic: Topic) {
    this.selectedSubReason = this.selectedSubReason === selectedTopic ? null : selectedTopic;
    this.isTopicSyncVisible = !this.isTopicSyncVisible;
    this.isContactFormVisible = true;
    this.subReason = selectedTopic.topic;
  }

  save(): void {
    if (this.contactForm?.invalid) {
      return;
    }
    this.resetNotifications();
    let faqContact: ContactRequest = {
      source: ContactRequestSource.FaqContact,
      customerType: this.customerType,
      reason: this.reason,
      subReason: this.subReason,
      salutation: this.contactFormGroup.controls.salutation.value === null ?
        Salutation.NotSpecified :
        this.contactFormGroup.controls.salutation.value,
      firstName: this.contactFormGroup.controls.firstName.value,
      lastName: this.contactFormGroup.controls.lastName.value,
      email: this.contactFormGroup.controls.email.value,
      phone: this.contactFormGroup.controls.phone.value,
      contractId: this.contactFormGroup.controls.contractId.value,
      message: this.contactFormGroup.controls.message.value,
      filename: this.fileName,
      file: this.selectedFile
    }
    withSlowLoadingStatus(
      this.faqService.createContactRequest(faqContact)
    ).pipe(takeUntil(this.destroyed$))
      .subscribe(contactSendResponseState => {
        this.contactSendResponseState = contactSendResponseState;
        if (this.contactSendResponseState.isInValueState) {
          if (this.contactSendResponseState.value?.isSuccessFul) {
            this.isResettingForm = true;
            this.resetForm();
          }
        }
      });
  }

  resetForm() {
    this.contactForm?.resetForm();
    this.refreshCaptcha();
    this.fileName = '';
    setTimeout(() => (this.isResettingForm = false), 5000);
  }
}
