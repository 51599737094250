<app-breadcrumb></app-breadcrumb>

<mat-card-title>
  <h1 class="mat-headline">
    Zu welchem Thema?
  </h1>
</mat-card-title>

<div class="flex flex-wrap">
  <ng-container *ngIf="favourites.length > 0">
    <div class="w-full">
      <app-favourites [favourites]="favourites"></app-favourites>
    </div>
  </ng-container>
  <ng-container *ngIf="contactTopics.length > 0">
    <div class="w-full my-8">
      <p class="font-bold">Zu welchem Thema haben Sie eine Frage?</p>
      <app-topics [topics]="contactTopics"></app-topics>
    </div>
  </ng-container>
</div>

<ng-container *ngIf="contactState.isInValueState && contactTopics.length === 0">
  <div class="w-full my-8">
    <cpc-notification [notificationType]="NotificationType.Information"
                      notification="Keine Themen verfügbar.">
    </cpc-notification>
  </div>
</ng-container>

<div class="flex flex-wrap justify-center">
  <ng-container *ngIf="contactState.isInLoadingState">
    <div class="m-auto flex items-center my-16 w-full z-10">
      <mat-spinner class="m-auto"></mat-spinner>
    </div>
  </ng-container>

  <ng-container *ngIf="contactState.isInErrorState">
    <div class="w-full my-8">
      <cpc-notification [notificationType]="NotificationType.Error"
                        notification="Die Themen sind derzeit nicht verfügbar. Bitte versuchen Sie es später erneut.">
      </cpc-notification>
    </div>
  </ng-container>
</div>
