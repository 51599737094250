<div class="fadedBG">
  <div class="container mx-auto px-4 py-16">
    <mat-card-title>
      <h1 class="mat-headline text-center">
        Wie können wir Ihnen weiterhelfen?
      </h1>
    </mat-card-title>
    <div class="flex flex-wrap justify-center">
      <div class="w-full lg:w-1/2">
        <mat-form-field class="w-full mt-5 input-search" appearance="fill">
          <mat-label class="opacity-20">Suche</mat-label>
          <mat-icon matPrefix class="opacity-20 mr-2">search</mat-icon>
          <input
            type="text"
            matInput
            [formControl]="tagControl"
            [matAutocomplete]="auto"/>
          <mat-autocomplete #auto="matAutocomplete"
                            (optionSelected)="onTagClick($event.option.value)">
            <mat-option *ngFor="let tag of filteredTags$ | async" [value]="tag">
              {{ tag.tag }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>

        <ng-container *ngIf="searchTags">
          <div class="my-2 flex flex-wrap">
            <ng-container *ngFor="let tag of searchTags">
              <a class="py-2 px-3 rounded-lg primaryCategoryBackgroundColor m-1" (click)="onTagClick(tag)">
                {{ tag.tag }}
              </a>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<div class="container mx-auto px-4 py-8">
  <ng-container *ngIf="faqState.isInValueState">

    <ng-container *ngIf="favourites.length > 0">
      <div class="my-20 flex flex-wrap justify-center">
        <mat-card-title class="w-full">
          <h1 class="mat-headline text-center">
            Häufig gesucht
          </h1>
        </mat-card-title>
        <div class="w-full">
          <app-favourites [favourites]="favourites"></app-favourites>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="topics.length > 0">
      <div class="my-12 flex flex-wrap justify-center">
        <mat-card-title>
          <h1 class="mat-headline text-center">
            Alle Themen
          </h1>
        </mat-card-title>
        <div class="w-full">
          <app-topics [topics]="topics"></app-topics>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="topics.length === 0">
      <div class="w-full my-8">
        <cpc-notification [notificationType]="NotificationType.Information"
                          notification="Keine Themen verfügbar.">
        </cpc-notification>
      </div>
    </ng-container>
  </ng-container>

  <div class="flex flex-wrap justify-center">
    <ng-container *ngIf="faqState.isInLoadingState">
      <div class="m-auto flex items-center my-16 w-full z-10">
        <mat-spinner class="m-auto"></mat-spinner>
      </div>
    </ng-container>

    <ng-container *ngIf="faqState.isInErrorState">
      <div class="w-full my-8">
        <cpc-notification [notificationType]="NotificationType.Error"
                          notification="Die Themen sind derzeit nicht verfügbar. Bitte versuchen Sie es später erneut.">
        </cpc-notification>
      </div>
    </ng-container>

    <div class="w-full text-center">
      <a [routerLink]="'/faq/contact'" class="mat-title">Haben Sie eine andere Frage?</a>
    </div>
  </div>

</div>

