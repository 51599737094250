import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Subject, takeUntil} from 'rxjs';
import {EnergyType} from '../../shared/enumerations/energy-type';
import {Contract} from '../../shared/interfaces/contract';
import {AuthService} from '../../shared/services/auth.service';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  environment = environment;
  private destroyed = new Subject<void>();
  contract: Contract | null = null;
  EnergyType = EnergyType;
  @Input() showTariff: boolean = false;

  constructor(public authService: AuthService, private router: Router) {}

  ngOnInit(): void {
    this.authService.contractChanged$.pipe(takeUntil(this.destroyed)).subscribe((contract) => {
      this.contract = contract;
    });
  }

  logout() {
    this.authService.logout();
    this.router.navigate([this.environment.loginUrl]);
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
  }

}
