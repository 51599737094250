import {Injectable} from '@angular/core';
import {KeyLabelViewModel} from "../interfaces/key-label-view-model";
import {Salutation} from "../enumerations/salutation";

@Injectable({
  providedIn: 'root'
})
export class SalutationSelectionService  {
  constructor() {
  }

  getSalutationViewModels(): KeyLabelViewModel<Salutation>[] {
    return [
      {key: Salutation.NotSpecified, label: 'Keine Angabe'},
      {key: Salutation.Mr, label: 'Herr'},
      {key: Salutation.Mrs, label: 'Frau'}
    ];
  }
}
