import {Component, Input} from '@angular/core';
import {Topic} from "../interfaces/topic";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-topics',
  templateUrl: './topics.component.html'
})
export class TopicsComponent {
  @Input() topics: Topic[] = [];
  containsContact: boolean = false;

  constructor(private router: Router,
              private route: ActivatedRoute) {
  }

  selectTopic(topic: Topic) {
    this.containsContact = this.router.url.includes('contact');
    let basePath: string | null = '';
    if (this.containsContact) {
      basePath = '/faq/contact';
      const customerType = this.route.snapshot.queryParams['customerType'];
      this.router.navigate([`${basePath}`], {
        queryParams: {
          customerType: customerType,
          topicId: topic.id
        }
      });
    } else {
      basePath = '/faq/topics';
      const topicPath = `${basePath}/${topic.id}`;
      this.router.navigate([topicPath]);
    }
  }
}
