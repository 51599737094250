import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from '../../shared/services/auth.service';
import { MeterReading } from '../interfaces/meter-reading';
import { MeterReadingCreation } from '../interfaces/meter-reading-creation';
import { Meter } from "../interfaces/meter";
import { MeterReadingCreationValidity } from '../interfaces/meter-reading-creation-validity';

@Injectable({
  providedIn: 'root'
})
export class MeterReadingService {

  constructor(private authService: AuthService, private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) {
  }

  createMeterReading(contractId: string, value: MeterReadingCreation): Observable<any> {
    let headers = new HttpHeaders()
      .set('authorization', `Bearer ${this.authService.jwt}`);
    return this.http.post<any>(`${this.baseUrl}api/meter-readings/${contractId}`, value, { headers: headers });
  }

  validateMeterReadingAsync(contractId: string, value: MeterReadingCreation): Observable<MeterReadingCreationValidity> {
    let headers = new HttpHeaders()
      .set('authorization', `Bearer ${this.authService.jwt}`);
    return this.http.post<MeterReadingCreationValidity>(`${this.baseUrl}api/meter-readings/${contractId}/validate`, value, { headers: headers });
  }

  getMeterReadings(contractId: string): Observable<MeterReading[]> {
    let headers = new HttpHeaders()
      .set('authorization', `Bearer ${this.authService.jwt}`);
    return this.http.get<MeterReading[]>(`${this.baseUrl}api/meter-readings/${contractId}`, { headers: headers });
  }

  getLastMeterReading(contractId: string): Observable<MeterReading> {
    let headers = new HttpHeaders()
      .set('authorization', `Bearer ${this.authService.jwt}`);
    return this.http.get<MeterReading>(`${this.baseUrl}api/meter-readings/last/${contractId}`, { headers: headers });
  }

  getActiveMeter(contractId: string): Observable<Meter> {
    let headers = new HttpHeaders()
      .set('authorization', `Bearer ${this.authService.jwt}`);
    return this.http.get<Meter>(`${this.baseUrl}api/meter/${contractId}/active`, { headers: headers });
  }

}
