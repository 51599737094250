import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, Input, ViewChild} from '@angular/core';
import {Favourite} from "../interfaces/favourite";
import {Router} from "@angular/router";
import {FavouriteType} from "../enumerations/favourite-type";
import {FaqService} from "../services/faq.service";

@Component({
  selector: 'app-favourites',
  templateUrl: './favourites.component.html'
})
export class FavouritesComponent implements AfterViewInit {
  @Input() favourites: Favourite[] = [];
  @ViewChild('scrollContainer') scrollContainer!: ElementRef<HTMLDivElement>;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.updateItemsPerPage();
  }

  FavouriteType = FavouriteType;
  currentIndex: number = 0;
  visiblePages: number[] = [];
  showButtons: boolean = true;
  showNextButton: boolean = true;
  showPrevButton: boolean = false;

  constructor(private faqService: FaqService, private router: Router, private cdr: ChangeDetectorRef) {
  }

  ngAfterViewInit() {
    this.calculateVisiblePages();
    this.cdr.detectChanges();
  }

  getRouteLabelDisplay(routeLabel: FavouriteType): string {
    switch (routeLabel) {
      case FavouriteType.SameTab:
        return "Kundenportal";
      case FavouriteType.NewTab:
        return "Kundenportal";
      case FavouriteType.NewWindow:
        return "Extern";
      case FavouriteType.Tag:
        return "Suchbegriff";
      case FavouriteType.Topic:
        return "Thema";
      case FavouriteType.Question:
        return "Frage";
      default:
        return "?";
    }
  }

  openFavourite(favourite: Favourite): void {
    switch (favourite.type) {
      case FavouriteType.SameTab:
        window.open(favourite.link, '_self');
        break;

      case FavouriteType.NewTab:
        window.open(favourite.link, '_blank');
        break;

      case FavouriteType.NewWindow:
        window.open(favourite.link, '_blank', 'location=1,status=1,scrollbars=1');
        break;

      case FavouriteType.Tag:
        this.router.navigate(['/faq/tags', favourite.link]);
        break;

      case FavouriteType.Topic:
        this.router.navigate(['/faq/topics', favourite.link]);
        break;

      case FavouriteType.Question:
        let questionId = Number(favourite.link);

        this.faqService.getFaqTopicForQuestionAsync(questionId).subscribe(topicId => {
          this.router.navigate(['/faq/topics', topicId], {queryParams: {questionId: questionId}});
        });
        break;
    }
  }

  scrollRight() {
    const container = this.scrollContainer.nativeElement;
    const itemWidth = (container.querySelector('.scroll-item') as HTMLElement)?.offsetWidth || 0;
    const containerWidth = container.clientWidth;
    const maxScrollPosition = container.scrollWidth - containerWidth + 100;
    let newScrollPosition = container.scrollLeft + itemWidth;

    if (newScrollPosition > maxScrollPosition) {
      this.scrollToPosition(0);
      this.currentIndex = 0;
    } else {
      this.scrollToPosition(newScrollPosition);
      this.currentIndex = (this.currentIndex + 1) % this.favourites.length;
    }
    this.updateButtonVisibility();
  }

  scrollLeft() {
    const container = this.scrollContainer.nativeElement;
    const itemWidth = (container.querySelector('.scroll-item') as HTMLElement)?.offsetWidth || 0;
    let newScrollPosition = container.scrollLeft - itemWidth;

    if (newScrollPosition < 0) {
      this.scrollToPosition(0);
      this.currentIndex = 0;
    } else {
      this.scrollToPosition(newScrollPosition);
      this.currentIndex = (this.currentIndex - 1 + this.favourites.length) % this.favourites.length;
    }
    this.updateButtonVisibility();
  }

  goToPage(page: number): void {
    const container = this.scrollContainer.nativeElement;
    const itemWidth = (container.querySelector('.scroll-item') as HTMLElement).offsetWidth || 0;
    this.scrollToPosition(page * itemWidth);
    this.currentIndex = page;
    this.updateButtonVisibility();
  }

  calculateVisiblePages(): void {
    const container = this.scrollContainer.nativeElement;
    const containerWidth = container.clientWidth;
    const itemWidth = (container.querySelector('.scroll-item') as HTMLElement).offsetWidth;

    if (!itemWidth || container.scrollWidth <= containerWidth) {
      this.showButtons = false;
      this.visiblePages = [];
      return;
    }

    this.showButtons = true;

    let visibleCount: number;
    let itemsPerPage = window.innerWidth >= 1280 ? 2 : 1;
    if (container.scrollWidth === Math.floor(containerWidth + Math.floor((container.scrollWidth - containerWidth) % containerWidth))) itemsPerPage = 2;

    containerWidth > itemWidth * 2 ?
      visibleCount = Math.floor((container.scrollWidth - containerWidth) / itemWidth) + itemsPerPage :
      visibleCount = Math.floor(container.scrollWidth / itemWidth);

    this.visiblePages = Array.from({length: Math.min(this.favourites.length, visibleCount)}, (_, index) => index);
  }

  updateItemsPerPage(): void {
    this.currentIndex = 0;
    this.scrollToPosition(0);
    this.showPrevButton = false;
    this.showNextButton = true;
    this.calculateVisiblePages();
  }

  private scrollToPosition(position: number): void {
    const container = this.scrollContainer.nativeElement;
    container.scrollTo({left: position, behavior: 'smooth'});
  }

  updateButtonVisibility() {
    this.showPrevButton = this.currentIndex > 0;
    this.showNextButton = this.currentIndex + 1 < this.visiblePages.length;
  }
}
