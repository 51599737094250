import {Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import {combineLatest, of, Subject, switchMap, takeUntil} from "rxjs";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {withNormalLoadingStatus} from "../../observable-status/extensions/observable.extension";
import {ObservableStatus} from "../../observable-status/classes/observable-status";
import {FaqService} from "../services/faq.service";
import {Question} from "../interfaces/question";
import {Faq} from "../interfaces/faq";
import {Topic} from "../interfaces/topic";
import {NotificationType} from '../../notification/enumerations/notification-type';
import {Favourite} from "../interfaces/favourite";
import {filter} from "rxjs/operators";
import {CustomerType} from "../enumerations/customer-type";
import {MetaService} from "../services/meta.service";

@Component({
  selector: 'app-topic',
  templateUrl: './topic.component.html'
})
export class TopicComponent implements OnInit, OnDestroy {
  @HostBinding('class') flexClass = 'flex-1';

  private refresh$ = new Subject<void>();
  private destroyed$ = new Subject<void>();

  topicId: number | null = null;
  questionId: number | null = null;

  faqState: ObservableStatus<Faq> = ObservableStatus.none<Faq>();
  topic: Topic | null = null;
  favourites: Favourite[] = [];
  subTopics: Topic[] = [];
  questions: Question[] = [];
  allQuestions: Question[] = [];
  keywords: string[] = [];
  NotificationType = NotificationType;
  customerType: CustomerType = CustomerType.NoCustomer;

  constructor(private activatedRoute: ActivatedRoute,
              private faqService: FaqService,
              private router: Router,
              private metaService: MetaService) {
  }

  ngOnInit(): void {
    this.initWithRouteParameters();
    this.customerType =  this.activatedRoute.snapshot.queryParams['customerType'];
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        takeUntil(this.destroyed$)
      )
      .subscribe(() => this.refresh());

    this.refresh$
      .pipe(
        switchMap(() => {
          if (!this.topicId) {
            return of(ObservableStatus.none<Faq>());
          }

          return withNormalLoadingStatus(this.faqService.getFaq(null, this.topicId));
        }),
        takeUntil(this.destroyed$)
      ).subscribe((faqState) => {
      this.faqState = faqState;
      this.topic = this.faqState.value?.topics.find(t => t.id == this.topicId) || null;
      this.favourites = this.faqState.value?.favourites || [];
      this.allQuestions = this.faqState.value?.questions || [];
      this.keywords = this.faqState.value?.keywords?.map(keyword => keyword.tag) || [];
      this.subTopics = this.subTopics = this.faqState.value?.subTopics || [];
      this.questions = this.faqState.value?.questions || [];

      this.metaService.updateKeywords(this.keywords);
    });

    this.refresh();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  refresh(): void {
    this.refresh$.next();
  }

  private initWithRouteParameters(): void {
    combineLatest([
      this.activatedRoute.params,
      this.activatedRoute.queryParams
    ])
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(([params, queryParams]) => {
        this.topicId = params.id ? Number(params.id) : null;
        this.questionId = queryParams.questionId
          ? Number(queryParams.questionId)
          : null;
      });
  }
}
