import {Component, HostBinding, OnInit} from '@angular/core';
import {CustomerType} from "../enumerations/customer-type";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html'
})
export class ContactComponent implements OnInit{

  @HostBinding('class') flexClass = 'flex-1';
  CustomerType = CustomerType;
  customerType: CustomerType | null = null;
  topicId: number | null = null;

  constructor(private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.customerType = params['customerType'] || null;
      this.topicId = params['topicId'] ? +params['topicId'] : null;
    });
  }
}
