import {Injectable} from '@angular/core';
import {Meta} from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class MetaService {
  constructor(private meta: Meta) {
  }

  updateKeywords(keywords: string[]): void {
    const keywordsString = keywords.join(', ');

    this.meta.removeTag("name='keywords'");

    this.meta.addTag({name: 'keywords', content: keywordsString});
  }
}
